<style lang="scss">
#frontend {
  .header-intro-generel-box {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 50px;
    background-size: cover;
    .inner-image-box {
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 700px;
      background-position: center top;
      position: relative;

      //border:1px solid red;
      @media (min-width: 2000px) {
        height: 800px;
      }
      @media (min-width: 3000px) {
        height: 900px;
      }
      @media (max-width: 1600px) {
        height: 600px;
      }
      @media (max-width: 1200px) {
        height: 500px;
      }
      @media (max-width: $width-lg) {
        background-position: center 50px;
      }
      @media (max-width: $width-md) {
        height: 350px;
      }

      .title-box {
        position: relative;
        top: 50%;
        transform: translateY(-45%);

        @media (max-width: $width-md) {
          display: block;
        }
        h1 {
          text-align: center;
          
          padding:0 20px;

          span {
            font-family: 'Helvetica Neue LT W05 96 Blk It';
            display: block;
            color: #fff;
            -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #fff;
            text-transform: uppercase;
            font-style: italic;
            font-weight: 700;
            font-size: 180%;
            @media (max-width: $width-lg) {
              font-size: 130%;
            }
            @media (max-width: $width-md) {
              font-size: 110%;
            }
          
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="header-intro-generel-box">
    <div class="inner-image-box" :style="headerImage">
      <div class="container title-box">
        <h1>
          <span>{{ headerImageText }}</span>
        </h1>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    headerImage: {
      type: String,
      required: true,
    },
    headerImageText: {
      type: String,
    },
  },
});
</script>
