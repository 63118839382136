
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    headerImage: {
      type: String,
      required: true,
    },
    headerImageText: {
      type: String,
    },
  },
});
