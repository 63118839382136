
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import MarkenBoxenServicePartner from '../../../components/Frontend/MarkenBoxenServicePartner.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Service Partner | Styria Media Groupp',
      keywords: ['service partner', 'marketplaces' , 'brands' , 'digital' , 'styria' ,'cope'],
      description: 'Die Services der STYRIA: Druck, IT, Agenturen, Kundenmanagement, Logistik, Rechnungswesen.',
      image: require('@/assets/img/og/brands.png'),
      
    },
    en: {
      title: 'Service partner | Stryria Media Group',
      keywords: ['service partner', 'marketplaces' , 'brands' , 'digital' , 'styria' ,'cope'],
      description: "STYRIA's services: printing, IT, agencies, customer management, logistics, accounting.",
      image: require('@/assets/img/og/brands.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    MarkenBoxenServicePartner,
    HeaderImageBoxGenerel,
    Footer,
    },
    setup() {
  
    return {
      mdiChevronUp,
    };
  },
  
});
