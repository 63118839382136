<style lang="scss">
#debug {
  display:none;
}
#frontend {
  .service-partner-all {
   .header-intro-generel-box {
      background-position:center -200px !important;
      @media(max-width:1250px) {
        background-position:center center !important;
      }
    }
  }
}
  

</style>

<template>
<SocialMediaIconBox />
<div class="service-partner-all">
  <HeaderImageBoxGenerel :style="{ 'background-image': `url(${require('@/assets/img/marken/service-partner-header-img.jpg')})` }"  headerImageText="" />
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="headline-box-std">
          <div class="headline-inner">
            <div class="breadcrumb"><span>{{$t('General.brands')}}</span></div>
            <h1 v-html="$t('service-partner.service-partner')"></h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-7">
          <p class="intro-txt text-center padding-t-m padding-b-m">{{$t('service-partner.intro-txt')}}</p>
        </div>
      </div>
    </div>
</div>
<MarkenBoxenServicePartner />
<Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import MarkenBoxenServicePartner from '../../../components/Frontend/MarkenBoxenServicePartner.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Service Partner | Styria Media Groupp',
      keywords: ['service partner', 'marketplaces' , 'brands' , 'digital' , 'styria' ,'cope'],
      description: 'Die Services der STYRIA: Druck, IT, Agenturen, Kundenmanagement, Logistik, Rechnungswesen.',
      image: require('@/assets/img/og/brands.png'),
      
    },
    en: {
      title: 'Service partner | Stryria Media Group',
      keywords: ['service partner', 'marketplaces' , 'brands' , 'digital' , 'styria' ,'cope'],
      description: "STYRIA's services: printing, IT, agencies, customer management, logistics, accounting.",
      image: require('@/assets/img/og/brands.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    MarkenBoxenServicePartner,
    HeaderImageBoxGenerel,
    Footer,
    },
    setup() {
  
    return {
      mdiChevronUp,
    };
  },
  
});
</script>
